import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import styles from './styles';
import { withStyles } from '@material-ui/core/styles';

const Page = ({ classes }) => {
  return (
    <div className={classes.root}>
      <Typography variant='h5' gutterBottom>Website Terms of Use</Typography>
      <Typography gutterBottom variant='body1'>
        These "Terms of Use" set forth the terms and conditions that apply to your use of toothfairyspc.com/ (the "Website"). By using the Website (other than to read this page for the first time), you agree to comply with all of the Terms of Use set forth herein. The right to use the Website is personal to you and is not transferable to any other person or entity.
      </Typography>
      <Typography variant='h6' gutterBottom>Copyrights and Trademarks</Typography>
      <Typography gutterBottom variant='body1'>
        A. All materials contained on the Website are Copyright January 22, 2013, Tooth Fairys, P.C.. All rights reserved.
        B. No person is authorized to use, copy or distribute any portion the Website including related graphics.
        C. Tooth Fairys, P.C. and other trademarks and/or service marks (including logos and designs) found on the Website are trademarks/service marks that identify Tooth Fairys, P.C., Inc. and the goods and/or services provided by Tooth Fairys, P.C.. Such marks may not be used under any circumstances without the prior written authorization of Tooth Fairys, P.C..
      </Typography>
      <Typography variant='h6' gutterBottom>Links to Third-Party Website</Typography>
      <Typography gutterBottom variant='body1'>
        Tooth Fairys, P.C. may provide hyperlinks to third-party websites as a convenience to users of the Website. Tooth Fairys, P.C. does not control third-party websites and is not responsible for the contents of any linked-to, third-party websites or any hyperlink in a linked-to website. Tooth Fairys, P.C. does not endorse, recommend or approve any third-party website hyperlinked from the Website. Tooth Fairys, P.C. will have no liability to any entity for the content or use of the content available through such hyperlink.
      </Typography>
      <Typography variant='h6' gutterBottom>No Representations or Warranties; Limitations on Liability</Typography>
      <Typography gutterBottom variant='body1'>
        The information and materials on the Website could include technical inaccuracies or typographical errors. Changes are periodically made to the information contained herein. Tooth Fairys, P.C. MAKES NO REPRESENTATIONS OR WARRANTIES WITH RESPECT TO ANY INFORMATION, MATERIALS OR GRAPHICS ON THE WEB SITE, ALL OF WHICH IS PROVIDED ON A STRICTLY "AS IS" BASIS, WITHOUT WARRANTY OF ANY KIND AND HEREBY EXPRESSLY DISCLAIMS ALL WARRANTIES WITH REGARD TO ANY INFORMATION, MATERIALS OR GRAPHICS ON THE WEB SITE, INCLUDING ALL IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND NON-INFRINGEMENT. UNDER NO CIRCUMSTANCES SHALL THE SITE OWNER OR PUBLISHER BE LIABLE UNDER ANY THEORY OF RECOVERY, AT LAW OR IN EQUITY, FOR ANY DAMAGES, INCLUDING WITHOUT LIMITATION, SPECIAL, DIRECT, INCIDENTAL, CONSEQUENTIAL OR PUNITIVE DAMAGES (INCLUDING, BUT NOT LIMITED TO LOSS OF USE OR LOST PROFITS), ARISING OUT OF OR IN ANY MANNER CONNECTED WITH THE USE OF INFORMATION OR SERVICES, OR THE FAILURE TO PROVIDE INFORMATION OR SERVICES, FROM THE WEB SITE.
      </Typography>
      <Typography variant='h6' gutterBottom>Changes to These Terms of Use</Typography>
      <Typography gutterBottom variant='body1'>
        Tooth Fairys, P.C. reserves the right to change these Terms of Use at any time by posting new Terms of Use at this location. You can send e-mail to Tooth Fairys, P.C. with any questions relating to these Terms of Use via our contact form.
      </Typography>
    </div>
  )
};

Page.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Page);
