import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import styles from './styles';
import { withStyles } from '@material-ui/core/styles';

const Page = ({ classes }) => {
  return (
    <div className={classes.root}>
      <Typography variant='h5' gutterBottom>Website Privacy Policy</Typography>
      <Typography variant='h6' gutterBottom>1. Overview</Typography>
      <Typography gutterBottom variant='body1'>
        Thank you for visiting toothfairyspc.com (the &apos;Web Site&apos;) and reviewing our Privacy Policy. Your privacy is important to us, and our policy is simple: we will collect no personally identifiable information about you when you visit the Web Site unless you choose to provide that information. This Privacy Policy does not describe information collection practices on other sites, including those linked to or from the Web Site.
      </Typography>
      <Typography variant='h6' gutterBottom>2. What Type of Information We Collect</Typography>
      <Typography gutterBottom variant='body1'>
        The server on which the Web Site is located collects and saves only the default information customarily logged by web server software. Such information may include the date and time of your visit, the originating IP address, and the pages and images requested.
      </Typography>
      <Typography variant='h6' gutterBottom>3. Children&apos;s Issues</Typography>
      <Typography gutterBottom variant='body1'>
        The Web Site is not directed to children under thirteen (13) years of age, and children under such age must not use the web site or services offered on it to submit any individually identifiable information about themselves.
      </Typography>
      <Typography variant='h6' gutterBottom>4. Copyright</Typography>
      <Typography gutterBottom variant='body1'>
        The materials found on the Web Site are protected by United States and other copyright laws, except for works of the United States Government pursuant to 1 U.S.C. Section 105.
      </Typography>
      <Typography variant='h6' gutterBottom>5. No Warranties</Typography>
      <Typography gutterBottom variant='body1'>
        toothfairyspc.com DOES NOT MAKE ANY EXPRESS OR IMPLIED WARRANTIES, REPRESENTATIONS OR ENDORSEMENTS WHATSOEVER (INCLUDING, WITHOUT LIMITATION, THE IMPLIED WARRANTIES OF MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE) WITH REGARD TO THE WEB SITE, THE MATERIALS, ANY PRODUCTS, INFORMATION OR SERVICE PROVIDED THROUGH THE WEB SITE, OR ANY SITES LISTED THEREIN, AND THE WEB SITE WILL NOT BE LIABLE FOR ANY COST OR DAMAGE ARISING EITHER DIRECTLY OR INDIRECTLY FROM ANY SUCH TRANSACTION. THE WEB SITE DOES NOT WARRANT THAT IT WILL BE UNINTERRUPTED OR ERROR-FREE OR THAT DEFECTS IN IT WILL BE CORRECTED. THE WEB SITE IS PROVIDED ON AN AS IS, AS AVAILABLE BASIS.
      </Typography>
      <Typography variant='h6' gutterBottom>5. Trademarks</Typography>
      <Typography gutterBottom variant='body1'>
        IN NO EVENT WILL THE WEB SITE OR ITS MEMBERS, MANAGERS, OFFICERS, EMPLOYEES, AGENTS AND THE ASSIGNS OF SAME BE LIABLE FOR (1) ANY INCIDENTAL, CONSEQUENTIAL OR INDIRECT DAMAGES (INCLUDING, BUT NOT LIMITED TO, DAMAGES FOR LOSS OF PROFITS, BUSINESS INTERRUPTION, LOSS OF PROGRAMS OR INFORMATION, AND THE LIKE) ARISING OUT OF THE USE OF OR INABILITY TO USE THE WEB SITE, THE MATERIALS OR ANY INFORMATION, OR TRANSACTIONS PROVIDED ON THE WEB SITE OR DOWNLOADED FROM THE WEB SITE, EVEN IF THE WEB SITE OR ITS AUTHORIZED REPRESENTATIVES HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES, OR (2) ANY CLAIM ATTRIBUTABLE TO ERRORS, OMISSIONS OR OTHER INACCURACIES IN THE WEB SITE, THE MATERIALS AND/OR LISTINGS OR INFORMATION DOWNLOADED THROUGH THE WEB SITE. BECAUSE SOME STATES DO NOT ALLOW THE EXCLUSION OR LIMITATION OF LIABILITY FOR CONSEQUENTIAL OR INCIDENTAL DAMAGES, THE ABOVE LIMITATION MAY NOT APPLY TO YOU. IN SUCH STATES, THE WEB SITE&apos;S LIABILITY IS LIMITED TO THE GREATEST EXTENT PERMITTED BY LAW.
      </Typography>
      <Typography variant='h6' gutterBottom>6. Indemnification</Typography>
      <Typography gutterBottom variant='body1'>
        You agree to indemnify, defend and hold harmless the Web Site and its members, managers, officers, employees, agents and the assigns of same, from and against any and all loss, costs, expenses (including reasonable attorneys&apos; fees and expenses), claims, damages and liabilities related to or associated with your use of the Web Site and any violation of this Privacy Policy by you.
      </Typography>
      <Typography variant='h6' gutterBottom>7. Governing Law and Jurisdiction</Typography>
      <Typography gutterBottom variant='body1'>
        This Privacy Policy constitutes a contract made under and shall be governed by and construed in accordance with the laws of the State of Colorado.
      </Typography>

    </div>
  )
};

Page.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Page);
