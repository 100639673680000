import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import BannerImage from 'global/BannerImage';
import styles from './styles';
import { withStyles } from '@material-ui/core/styles';

const Page = ({ classes }) => {
  return (
    <div className={classes.root}>
      <BannerImage imageSrc='/images/servicesBanner.jpg' imageAlt='Dental Services' />
      <Typography variant='h5' gutterBottom>Tooth / Dental Sealants</Typography>
      <Typography gutterBottom variant='body1'>
        Pit and fissure sealants are a great way to prevent decay from developing in the chewing surfaces of the teeth.
      </Typography>
      <Typography gutterBottom variant='body1'>
        The teeth are prepared by first being thoroughly cleaned and dried. Then a liquid material is placed into the groves on the biting surfaces of the teeth and cured with a light to harden. This results in sealing up the groves of the teeth and preventing decay.
      </Typography>
      <Typography gutterBottom variant='body1'>
        Sealants are very important in preventing cavities in children with newly erupted molars. Anytime is a good time to prevent decay. If a tooth does not yet have a cavity or filling then the grooves can be sealed at any age.
      </Typography>
      <Typography gutterBottom variant='body1'>
        New patients are always welcome.
      </Typography>
    </div>
  )
};

Page.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Page);
