import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import BannerImage from 'global/BannerImage';
import styles from './styles';
import { withStyles } from '@material-ui/core/styles';

const Page = ({ classes }) => {
  return (
    <div className={classes.root}>
      <BannerImage imageSrc='/images/servicesBanner.jpg' imageAlt='Dental Services' />
      <Typography variant='h5' gutterBottom>Periodontal Treatment & Deep Cleaning</Typography>
      <Typography gutterBottom variant='body1'>
        If your gums measurements are not within normal limits and you have bone loss, you may need a deep cleaning. Deep cleaning is generally the first step in treatment of periodontal disease.
      </Typography>
      <Typography gutterBottom variant='body1'>
        We can use a local anesthia or use a topical agent to insure your comfort. We take as much time as needed to thoroughly clean your teeth. We also use an antibiotic if needed to help the health of your gums return to normal limits.
      </Typography>
      <Typography gutterBottom variant='body1'>
        New patients are always welcome.
      </Typography>
    </div>
  )
};

Page.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Page);
