import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import BannerImage from 'global/BannerImage';
import styles from './styles';
import { withStyles } from '@material-ui/core/styles';

const Page = ({ classes }) => {
  return (
    <div className={classes.root}>
      <BannerImage imageSrc='/images/aboutBanner.jpg' imageAlt='Julie Elliott, Registered Dental Hygenist, Fort Collins'/>
      <Typography variant='h5' gutterBottom>About Julie Jo Elliott</Typography>
      <Typography gutterBottom variant='body1'>
        Julie is a Registered Dental Hygienist (RHD) with over 30 years of experience in dental offices. At 17 years old she started her first job in the dental office as a dental assistant. She knew then that she wanted to become a Dental Hygienist. Attending dental hygiene school at Cabrillo college in Santa Cruz, she received the golden scaler award for her class. Given to those with outstanding dental hygiene skills. Her mission is to provide affordable and quality dental hygiene care for children and adults. To meet each individual&apos;s needs and goals.
      </Typography>
      <Typography gutterBottom variant='body1'>
        Living and starting a family in Colorado for the past 20 years has been a true blessing. What a wonderful place to live, raise children and start a business!
      </Typography>
      <Typography variant='h6' gutterBottom>Our Dental hygiene philosophy</Typography>
      <Typography gutterBottom variant='body1'>
        Every one of my clients is treated as I would treat a member of my own family. I provide quality, affordable and gentle care to each and every patient.
      </Typography>
      <Typography variant='h6' gutterBottom>Independent Dental Hygiene</Typography>
      <Typography gutterBottom variant='body1'>
        Colorado allows direct access to dental health services, allowing properly licenced Dental Hygienists to operate indepedently. This allows affordable dental health care and preventative services such as cleaning to be done without the additional time and cost of consulting with a Dentist.
      </Typography>
      <Typography variant='h6' gutterBottom>Payment Options</Typography>
      <Typography gutterBottom variant='body1'>
        We accept most major insurance, CHP+ and medicaid in addition to cash, check and credit cards.
      </Typography>

    </div>
  )
};

Page.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Page);
