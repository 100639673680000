import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import BannerImage from 'global/BannerImage';
import styles from './styles';
import { withStyles } from '@material-ui/core/styles';

const Page = ({ classes }) => {
  return (
    <div className={classes.root}>
      <BannerImage imageSrc='/images/servicesBanner.jpg' imageAlt='Dental Services' />
      <Typography variant='h5' gutterBottom>Teeth Cleaning & Deep Dental Cleaning</Typography>
      <Typography gutterBottom variant='body1'>
        Now you can have a brighter and healthier smile. Get the just-cleaned, fresh feeling as often as you want.
      </Typography>
      <Typography gutterBottom variant='body1'>
        Cleaning or scaling removes tarter deposits (calculus) and plaque. We remove deposits above and below the gum line to reverse gingivitis and prevent periodontal disease.
      </Typography>
      <Typography gutterBottom variant='body1'>
        The time required and the cost of your teeth cleaning depends on the condition of your mouth and the amount of buildup on your teeth.
      </Typography>
      <Typography gutterBottom variant='body1'>
        After cleaning we polish your teeth to give them that smooth feeling, removing any plaque or stain.
      </Typography>
      <Typography gutterBottom variant='body1'>
        New patients are always welcome.
      </Typography>
    </div>
  )
};

Page.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Page);
