import React from 'react';
import PropTypes from 'prop-types';
import Copyright from '@material-ui/icons/Copyright';
import Button from '@material-ui/core/Button';

import styles from './footer.styl';
import { withStyles } from '@material-ui/core/styles';

const FooterCopyright = (props) => {
  const { classes } = props;
  return (
    <div className={classes.copyright}>
      <Button disabled className={classes.button} color="primary">
        <Copyright className={classes.leftIcon} />2018 Julie Jo Elliot
      </Button>
    </div>
  )
};

FooterCopyright.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(FooterCopyright);
