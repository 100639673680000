import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Toolbar from '@material-ui/core/Toolbar';
import { withStyles } from '@material-ui/core/styles';
import styles from './footer.styl';

import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';

import * as routes from 'constants/routes';

import FooterCopyright from './FooterCopyright';

const Footer = (props) => {
  const { classes } = props;

  return (
    <div className={classes.root}>
      <Toolbar className={classes.toolbar}>
        <div className={classes.footerHalf}>
          <div className={classes.footerHalf}>
            <FooterCopyright />
          </div>
          <div className={classes.footerHalf}>
            <Button className={classes.button} component={Link} to={routes.HOME} color='primary'>Home</Button>
            <Button className={classes.button} component={Link} to={routes.CONTACT} color='primary'>Contact</Button>
            <Button className={classes.button} component={Link} to={routes.TERMS} color='primary'>Terms</Button>
            <Button className={classes.button} component={Link} to={routes.PRIVACY} color='primary'>Privacy</Button>
          </div>
        </div>
        <div className={classes.footerHalf}>
          <div className={classes.spacer}></div>
          <div>
            <Typography variant='body2'> Uninsured welcome. Most insurance accepted including adult medicaid and CHP+</Typography>
          </div>
        </div>
      </Toolbar>
    </div>
  )
};

Footer.propTypes = {
  classes: PropTypes.object.isRequired,
};


export default withStyles(styles)(Footer);
