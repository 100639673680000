
const styles = theme => {
  const { unit } = theme.spacing;
  return {
    hr: {
      width: '100%',
      height: 1,
      background: 'grey',
      marginTop: unit * 2,
      marginBottom: unit * 2
    },
  }
};

export default styles;
