import React from 'react';
import PropTypes from 'prop-types';
import styles from './styles';
import { withStyles } from '@material-ui/core/styles';


const BannerImage = ({ classes, imageSrc, imageAlt }) => {
  return (
    <div className={classes.root}>
      <img src={imageSrc} alt={imageAlt}/>
    </div>
  )
};

BannerImage.propTypes = {
  classes: PropTypes.object.isRequired,
  imageSrc: PropTypes.string.isRequired,
  imageAlt: PropTypes.string.isRequired
};

export default withStyles(styles)(BannerImage);
