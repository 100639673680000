import React, { Component } from 'react';
import ReactGA from 'react-ga';
import Frame from './frame/Frame';
import Home from './pages/home';
import About from './pages/about';
import Billing from './pages/billing';
import Contact from './pages/contact';
import Privacy from './pages/privacy';
import Testimonials from './pages/testimonials';
import Terms from './pages/terms';
import Tips from './pages/tips';
import DentalServices from './pages/dentalServices';
import TeethCleaning from './pages/dentalServices/teethCleaning';
import TeethWhitening from './pages/dentalServices/teethWhitening';
import FlourideTreament from './pages/dentalServices/flourideTreatment';
import PeridontalCleaning from './pages/dentalServices/peridontalCleaning';
import DentalSealants from './pages/dentalServices/dentalSealants';
import NotFound404 from './pages/notFound404';

import { MuiThemeProvider } from '@material-ui/core/styles';
import mainTheme from './styles/mainTheme'
import { Route, Switch, withRouter } from 'react-router-dom';
import * as routes from 'constants/routes';
import './App.css';

ReactGA.initialize('UA-47043934-1');

class App extends Component {
  constructor() {
    super();
    this._lastPage = '';
  }

  componentDidUpdate() {
    const page = window.location.pathname;
    if (page !== this._lastPage) { ReactGA.pageview(page); }
    this._lastPage = page;
  }

  render() {
    return (
      <MuiThemeProvider theme={mainTheme}>
        <div className="App">
          <Frame>
            <Switch>
              <Route exact path={routes.HOME} component={Home} />
              <Route exact path={routes.ABOUT} component={About} />
              <Route exact path={routes.BILLING} component={Billing} />
              <Route exact path={routes.CONTACT} component={Contact} />
              <Route exact path={routes.PRIVACY} component={Privacy} />
              <Route exact path={routes.TERMS} component={Terms} />
              <Route exact path={routes.TESTIMONIALS} component={Testimonials} />
              <Route exact path={routes.TIPS} component={Tips} />
              <Route exact path={routes.DENTALSERVICES} component={DentalServices} />
              <Route exact path={routes.TEETHCLEANING} component={TeethCleaning} />
              <Route exact path={routes.TEETHWHITENING} component={TeethWhitening} />
              <Route exact path={routes.FLOURIDETREATMENT} component={FlourideTreament} />
              <Route exact path={routes.PERIODONTALCLEANING} component={PeridontalCleaning} />
              <Route exact path={routes.DENTALSEALANTS} component={DentalSealants} />
              <Route component={NotFound404} />
            </Switch>
          </Frame>
        </div>
      </MuiThemeProvider>
    );
  }
}

export default withRouter(App);
