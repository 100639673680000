import React from 'react';
import PropTypes from 'prop-types';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import HomeIcon from '@material-ui/icons/Home';
import DentalIcon from '@material-ui/icons/LocalHospital';
import TipsIcon from '@material-ui/icons/WhereToVote';
import TestimonialsIcon from '@material-ui/icons/SpeakerNotes';
import AboutIcon from '@material-ui/icons/Info';
import BillingIcon from '@material-ui/icons/Receipt';
import ContactIcon from '@material-ui/icons/ImportContacts';
import ListItemText from '@material-ui/core/ListItemText';
import { Link } from 'react-router-dom';
import Divider from '@material-ui/core/Divider';
import * as routes from 'constants/routes';
import { withStyles } from '@material-ui/core/styles';
import styles from './frame.styl';


const DrawerContents = ({ classes, handleDrawerToggle }) => {
  return (
    <div>
      <div className={classes.toolbar} />
      <Divider />
      <List>
        <ListItem button component={Link} to={routes.HOME} onClick={handleDrawerToggle}>
          <ListItemIcon>
            <HomeIcon />
          </ListItemIcon>
          <ListItemText primary="Home" />
        </ListItem>
        <ListItem button component={Link} to={routes.DENTALSERVICES} onClick={handleDrawerToggle}>
          <ListItemIcon>
            <DentalIcon />
          </ListItemIcon>
          <ListItemText primary="Dental Services" />
        </ListItem>
        <ListItem button component={Link} to={routes.TIPS} onClick={handleDrawerToggle}>
          <ListItemIcon>
            <TipsIcon />
          </ListItemIcon>
          <ListItemText primary="Tips" />
        </ListItem>
        <ListItem button component={Link} to={routes.TESTIMONIALS} onClick={handleDrawerToggle}>
          <ListItemIcon>
            <TestimonialsIcon />
          </ListItemIcon>
          <ListItemText primary="Testimonials" />
        </ListItem>
        <ListItem button component={Link} to={routes.ABOUT} onClick={handleDrawerToggle}>
          <ListItemIcon>
            <AboutIcon />
          </ListItemIcon>
          <ListItemText primary="About" />
        </ListItem>
        <ListItem button component={Link} to={routes.BILLING} onClick={handleDrawerToggle}>
          <ListItemIcon>
            <BillingIcon />
          </ListItemIcon>
          <ListItemText primary="Billing" />
        </ListItem>
        <ListItem button component={Link} to={routes.CONTACT} onClick={handleDrawerToggle}>
          <ListItemIcon>
            <ContactIcon />
          </ListItemIcon>
          <ListItemText primary="Contact" />
        </ListItem>
      </List>
      <Divider />
    </div>
  )
}


DrawerContents.propTypes = {
  classes: PropTypes.object.isRequired,
  handleDrawerToggle: PropTypes.func.isRequired
}

export default withStyles(styles)(DrawerContents);
