export const HOME = '/';
export const DENTALSERVICES = '/dentalservices';
export const TIPS = '/tips';
export const TESTIMONIALS = '/testimonials';
export const ABOUT = '/about';
export const BILLING = '/billing';
export const CONTACT = '/contact';
export const TERMS  = '/terms';
export const PRIVACY = '/privacy';
export const TEETHCLEANING = '/dentalservices/teeth-cleaning';
export const PERIODONTALCLEANING = '/dentalservices/peridiontal-cleaning';
export const DENTALSEALANTS = '/dentalservices/dental-sealants';
export const TEETHWHITENING = '/dentalservices/teeth-whitening';
export const FLOURIDETREATMENT = '/dentalservices/flouride-treatment';
