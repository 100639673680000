
const styles = theme => {
  const { unit } = theme.spacing;
  return {
    wrap: {
      display: 'flex',
      flexDirection: 'row',
      width: '100%',
      marginTop: unit * 2
    },
    left: {
      width: '33%'
    },
    right: {
      width: '66%'
    },
    spacer: {
      width: '100%',
      height: unit * 2
    }
  }
};

export default styles;
