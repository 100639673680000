
const styles = theme => {
  const { unit } = theme.spacing;
  return {
    root: {
      width: '100%',
      background: '#fff',
      marginBottom: unit * 2,
      '& img': {
        maxWidth: '100%'
      }
    },
  }
};

export default styles;
