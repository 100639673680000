import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import styles from './frame.styl';


const Header = ({ classes }) => {
  return (
    <div className={classes.headerRoot}>
      <div>
        <img
          className={classes.headerLogo}
          alt="Tooth Fairys, PC"
          src="/images/toothfairyspc_logo.png"
        />
      </div>
      <div>
        <Typography variant="h6" color="inherit" noWrap>Tooth Fairys, PC</Typography>
      </div>
      <div className={classes.spacer}/>
      <div className={classes.headerContact}>
        <Typography color="inherit" variant="subtitle2" noWrap>970-227-7202</Typography>
        <Typography color="inherit" variant="caption" noWrap>2000 Vermont Dr, Suite 210</Typography>
        <Typography color="inherit" variant="caption" noWrap>Fort Collins, CO 80525</Typography>
      </div>
    </div>
  )
}


Header.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(Header);
