import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import BannerImage from 'global/BannerImage';
import styles from './styles';
import { withStyles } from '@material-ui/core/styles';

const Page = ({ classes }) => {
  return (
    <div className={classes.root}>
      <BannerImage imageSrc='/images/tipsBanner.jpg' imageAlt='Oral health care tips' />
      <Typography variant='h5' gutterBottom>Oral Health Care Tips from Tooth Fairys</Typography>
      <Typography variant='h6' gutterBottom>Cavity Prevention for Babies</Typography>
      <Typography gutterBottom variant='body1'>
        Cavity prevention should actually start before teeth appear. In fact, baby teeth develop during the second trimester of pregnancy and approximately 20 teeth are present in the jaw at birth. For cavity prevention before teeth appear, use a damp washcloth to wipe a baby&apos;s gums after breast feeding or bottle feeding. Regular wiping of the gums can help prevent the buildup of bacteria, and it can help get your baby accustomed to regular oral care.
      </Typography>
      <Typography gutterBottom variant='body1'>
        Once baby teeth appear, your cavity prevention plan can include a soft-bristled toothbrush designed for infants and toddlers. Avoid using toothpaste at that point, just moisten the brush and gently clean the teeth and gums. Also avoid putting a baby to sleep with a bottle. Exposure to sugars in mild or juice overnight can damage developing tooth enamel, making cavity prevention more difficult.
      </Typography>
      <Typography gutterBottom variant='body1'>
        The American Dental Association recommends that children get off to a good start with having their first dental visit no later than their first birthday. During most initial visits, you will hold the baby on your lap while their mouth is checked. Consider coming to Tooth Fairys where all cleaning and evaluations are free until age 3.
      </Typography>

      <Typography variant='h6' gutterBottom>Teeth Sensitivity</Typography>
      <Typography gutterBottom variant='body1'>
        What does teeth sensitivity mean for your teeth? Teeth sensitivity can mean significant pain and it often impacts daily activities such as eating, drinking, and brushing your teeth, It can also lead to painful dentist office visits and procedures.
      </Typography>
      <Typography gutterBottom variant='body1'>
        In healthy teeth, porous tissue called dentin is protected by your gums and by you teeth&apos;s hard enamel outer layer. However, dentin can become exposed due to receding gums, fractured or chipped teeth or erosion. Microscopic holes in the dentin, called tubules, connect to the nerve, triggering pain when irritated by certain foods and beverages.
      </Typography>
      <Typography gutterBottom variant='body1'>
        Two recognized leading causes of teeth sensitivity are enamel loss and gingival recession. For example, brushing too hard, your age or too many acidic foods can increase the likelihood of sensitivity.
      </Typography>
      <Typography gutterBottom variant='body1'>
        Treatment for sensitive teeth may include, using a soft-bristle toothbrush or a desensitizing toothpaste. You may also need a prescription tooth paste to help protect and coat the teeth, which we always have available at Tooth Fairys.
      </Typography>

      <Typography variant='h6' gutterBottom>Causes of Bad Breath</Typography>
      <Typography gutterBottom variant='body1'>
        Bad breath generally stems from bacteria in the mouth, often on the tongue. Bad breath can also be caused by food, poor dental hygiene, tobacco use and certain medical conditions.
      </Typography>
      <Typography gutterBottom variant='body1'>
        To help you get rid of bad breath, make sure to brush twice a day and floss at least once a day to remove odor causing bacteria from the mouth, tongue and teeth. Anti-bacterial rinses are also effective. To help keep your breath fresh between brushing try: Rinsing your mouth with water after eating, chewing sugarless gum to stimulate saliva flow, which naturally cleanses teeth. Try snacking on celery, carrots or apples, which can loosen food debris while chewing. Try eating a nutritious diet and avoid the sugar. Include brushing the tongue in addition to the teeth and gums. And last but not least, have your teeth professionally cleaned by a dental hygienist.
      </Typography>

      <Typography variant='h6' gutterBottom>Tips for Dry Mouth</Typography>
      <Typography gutterBottom variant='body1'>
        If you&apos;ve developed a dry mouth, check your medication. You may need to change the medication or the dosage.
      </Typography>
      <Typography gutterBottom variant='body1'>
        Remedies that can help:
      </Typography>

      <List>
        <ul>
          <ListItem><ListItemText>
            Chewing a gum that is sugar-free.
          </ListItemText></ListItem>
          <ListItem><ListItemText>
            Drinking large quantities of water.
          </ListItemText></ListItem>
          <ListItem><ListItemText>
            Using a fluoride toothpaste or fluoride oral rinse.
          </ListItemText></ListItem>
          <ListItem><ListItemText>
            Using a vaporizer to moisturize the air.
          </ListItemText></ListItem>
          <ListItem><ListItemText>
            Remembering to keep your mouth closed and breath through your nose.
          </ListItemText></ListItem>
          <ListItem><ListItemText>
            Use over the counter medications for saliva substitutes.
          </ListItemText></ListItem>
        </ul>
      </List>

      <Typography variant='h6' gutterBottom>Warning Signs of Oral Cancer</Typography>
      <Typography gutterBottom variant='body1'>
        Oral cancer occurs most often in adults over the age of 40 and occurs in men twice as often as women. Tobacco use causes the majority of oral cancers - 75% of those diagnosed use tobacco. The use of cigarettes, cigars, pipes, chewing tobacco and dipping snuff are all linked to oral cancer. Not surprisingly, heavy smoker who have used tobacco over an extended period of time, or those who smoke and drink excessive amounts of alcohol, are at the highest risk. Anyone can develop oral cancer.
      </Typography>
      <Typography gutterBottom variant='body1'>
        If you notice any of the following signs, contact your dental professional immediately:
      </Typography>
      <List>
        <ul>
          <ListItem><ListItemText>
            A mouth sore that bleeds easily and doesn&apos;t heal.
          </ListItemText></ListItem>
          <ListItem><ListItemText>
            Patches inside the mouth or on the lips that are white, or a combination of both.
          </ListItemText></ListItem>
          <ListItem><ListItemText>
            Pain, tenderness or numbness anywhere in the mouth or on the lips.
          </ListItemText></ListItem>
          <ListItem><ListItemText>
            Bleeding in your mouth not related to gingivitis.
          </ListItemText></ListItem>
          <ListItem><ListItemText>
            Difficulty or pain when swallowing.
          </ListItemText></ListItem>
          <ListItem><ListItemText>
            Difficulty wearing dentures.
          </ListItemText></ListItem>
          <ListItem><ListItemText>
            A lump in your neck.
          </ListItemText></ListItem>
        </ul>
      </List>
      <Typography gutterBottom variant='body1'>
        Early detection can greatly improve chances for a successful treatment. If you have any of the warning signs visit your dental professional for an oral cancer screening.
      </Typography>

      <Typography variant='h6' gutterBottom>First Aid for Bleeding Gums</Typography>
      <Typography gutterBottom variant='body1'>
        It is estimated that more than three quarters of adults in America have gingivitis, the first signs of gum disease. Take bleeding gums seriously. While you don&apos;t need to panic, you do need to take action to help prevent the progression to a more advanced form of gum disease.
      </Typography>
      <Typography gutterBottom variant='body1'>
        Brush often: at least twice a day. Brush with a soft-bristle toothbrush in a circular motion. This will help stimulate your gums and strengthen them. At Tooth Fairys we may recommend an electric tooth brush to help aid you with your brushing.
      </Typography>
      <Typography gutterBottom variant='body1'>
        Floss daily: Flossing is one of the most important ways to help prevent bleeding gums and maintain good oral health. It removes plaque and food particles that may be under the gum line and lead to bleeding gums. If you&apos;re not a flosser, we may also recommend a water pick to help remove debris between your teeth. See your dental hygienist regularly. The cleaning performed at least twice a year by your dental hygienist will remove plaque and tarter that you can&apos;t remove at home.
      </Typography>
      <Typography gutterBottom variant='body1'>
        This will help prevent bleeding gums and keep your mouth healthy.
      </Typography>

      <Typography variant='h6' gutterBottom>Treating Gum Infection and Disease</Typography>
      <Typography gutterBottom variant='body1'>
        As soon as you notice a gum infection, you should consult a dental professional. Signs of gum infection include gums that are swollen, tender or bleeding; receding gums; pus coming from the gums; chronic bad breath; loose teeth; or a noticeable change in the way your teeth fit together when you bite.
      </Typography>
      <Typography gutterBottom variant='body1'>
        Once gum infection or gum disease is diagnosed by a painless method called periodontal probing, which measures the bone around the gum line. You will have numerous treatment options that will vary based on the severity of the gum infection. Here are some common methods of treatments for gum infection:
      </Typography>

      <List>
        <ul>
          <ListItem><ListItemText>
            Antibiotic Therapy: Just as you&apos;d expect with any type of infection, antibiotics are often used to treat gum infection. Your dental professional may elect to use an antibiotic pill or injection directly into the gum infection site.
          </ListItemText></ListItem>
          <ListItem><ListItemText>
            Root Planing and Scaling Treatment: This advanced gum infection treatment cleans deeply between your gums and teeth, all the way to the roots.
          </ListItemText></ListItem>
          <ListItem><ListItemText>
            Surgical Interventions: If none of these other gum infection treatments work , you may need periodontal surgery and be referred to a periodontist for treatment.
          </ListItemText></ListItem>
        </ul>
      </List>
    </div>
  )
};

Page.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Page);
