import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import BannerImage from 'global/BannerImage';
import styles from './styles';
import { withStyles } from '@material-ui/core/styles';

const Page = ({ classes }) => {
  return (
    <div className={classes.root}>
      <BannerImage imageSrc='/images/servicesBanner.jpg' imageAlt='Dental Services' />
      <Typography variant='h5' gutterBottom>Teeth Whitening</Typography>
      <Typography gutterBottom variant='body1'>
        One of the first things people notice about you is your smile. And when it&apos;s a white, bright smile your confidence truly shines. Why wait any longer to have whiter teeth. At Tooth Fairys we can give you that million dollar smile without the price tag. For optimal result, we recommend that you have your teeth professionally cleaned prior to whitening.
      </Typography>
      <Typography variant='h6' gutterBottom>Teeth Whitening Products (Take-Home)</Typography>
      <Typography gutterBottom variant='body1'>
        Our take-home teeth whitening products take as little as 30 minutes a day. We offer disposable pre-filled trays or custom made trays to have a lifetime.
      </Typography>
      <Typography variant='h6' gutterBottom>Professional Teeth Whitening Treatments ( In-Office)</Typography>
      <Typography gutterBottom variant='body1'>
        Our in office teeth whitening treatment is light activated and requires just one session of less than 60 minutes. We use the fastest bleach and it features built-in desensitizers to minimize discomfort. Safe for even the most sensitive teeth. Your teeth become 2-8 shades whiter. Now that&apos;s something to smile about!
      </Typography>
      <Typography gutterBottom variant='body1'>
        New patients are always welcome.
      </Typography>
    </div>
  )
};

Page.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Page);
