

const styles = theme => {

  return {
    root: {
      width: '100%',
    },
    toolbar: {
      [theme.breakpoints.down('md')]: {
        flexDirection: 'column',
      },
    },
    footerHalf: {
      display: 'flex',
      flexDirection: 'row',
      [theme.breakpoints.down('md')]: {
        flexWrap: 'wrap',
        justifyContent: 'center'        
      },
    },
    spacer: {
      flex: 1
    },
    copyright: {
      width: 240
    }
  }
};

export default styles;
