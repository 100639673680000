import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import BannerImage from 'global/BannerImage';
import styles from './styles';
import { withStyles } from '@material-ui/core/styles';

const Page = ({ classes }) => {
  return (
    <div className={classes.root}>
      <BannerImage imageSrc='/images/aboutBanner.jpg' imageAlt='Julie Elliott, Registered Dental Hygenist, Fort Collins'/>
      <Typography variant='h5' gutterBottom>Billing and Insurance</Typography>
      <Typography gutterBottom variant='body1'>
        For the most accurate information regarding your responsibility for any deductibles, co-insurance or
        co-payment at the time of service, please call us at 970-227-7202
      </Typography>
      <Typography variant='h6' gutterBottom>Commercial Insurance Carriers</Typography>
      <Typography gutterBottom variant='body1'>
        We accept most major insurance carriers, please call to confirm your carrier is accepted.
      </Typography>
      <Typography variant='h6' gutterBottom>Federal and State Government Programs</Typography>
      <Typography gutterBottom variant='body1'>
        We accept Colorado&apos;s Child Health Care Plus (CHP+) and State and Federal Medicaid plans for both adults and children.
      </Typography>
    </div>
  )
};

Page.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Page);
