import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import BannerImage from 'global/BannerImage';
import styles from './styles';
import { withStyles } from '@material-ui/core/styles';

const Page = ({ classes }) => {
  return (
    <div className={classes.root}>
      <BannerImage imageSrc='/images/servicesBanner.jpg' imageAlt='Dental Services' />
      <Typography variant='h5' gutterBottom>Fluoride Treatment</Typography>
      <Typography gutterBottom variant='body1'>
        For those with a history of excessive decay or sensitivity, our fluoride varnish helps strengthen tooth enamel and supports the remineralization process, preventing or minimizing sensitivity and cavities.
      </Typography>
      <Typography gutterBottom variant='body1'>
        New patients are always welcome.
      </Typography>
    </div>
  )
};

Page.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Page);
