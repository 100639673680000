import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { Link } from 'react-router-dom';
import styles from './styles';
import { withStyles } from '@material-ui/core/styles';
import * as routes from 'constants/routes';

const Page = ({ classes }) => {
  return (
    <div className={classes.root}>
      <Typography variant='h5' gutterBottom>Dental Services from a Registered Dental Hygienist</Typography>
      <Typography gutterBottom variant='body1'>
        Do you ever wish you could get your teeth professionally cleaned without having to visit a dentist?
        At <em>Tooth Fairys</em> we provide an alternative to the regular dental office setting.
      </Typography>
      <Typography gutterBottom variant='body1'>
        Maybe your teeth have started to look stained, dull or just feel fuzzy before it&apos;s time for your next dental appointment. Or you don&apos;t have dental insurance and don&apos;t want to break the bank just to get your teeth cleaned. Maybe you&apos;ve learned poor oral hygiene may contribute to poor overall health.
      </Typography>
      <Typography gutterBottom variant='body1'>
        Now, when using Tooth Fairys&apos;  services, the price of cleaning isn&apos;s t so alarming. Whether you have dental insurance or not you can get your teeth cleaned as often as you want. Not only are we affordable if you don&apos;t have dental insurance, but we will also bill most major Insurance companies for you. Additionally, we accept CHP+ and Medicaid plans for both adults and children.
      </Typography>
      <Typography gutterBottom variant='body1'>
        <em>Tooth Fairys</em> is a <Link to={routes.DENTALSERVICES}>full service dental hygiene practice</Link> for children and adults. We provide affordable and professional dental hygiene care and education. At Tooth Fairys you can have an impartial oral evaluation, cleaning, x-rays taken, and a fluoride treatment all done by a <Link to={routes.ABOUT}>registered Dental Hygienist.</Link>
      </Typography>
      <Typography gutterBottom variant='body1'>
        We provide  <Link to={routes.DENTALSEALANTS}>dental sealants</Link> for children, and periodontal evaluations and <Link to={routes.PERIODONTALCLEANING}>deep cleaning</Link> for adults.
      </Typography>
      <Typography gutterBottom variant='body1'>
        Our other services include one time <Link to={routes.TEETHWHITENING}>teeth whitening</Link> treatments and custom bleaching trays, to make your smile looks it&apos;s best!
      </Typography>
      <Typography gutterBottom variant='body1'>
        All services are provided without any pressure, just a relaxed and comfortable environment. We offer care coordination with the dentist of your choice. If you don&apos;t have a dental home, we can help you find a dentist that fits your needs. We still recommend that you see a dentist at least once a year, but coming to <em>Tooth Fairys</em> first is a great place to start!
      </Typography>
      <Typography gutterBottom variant='body1'>If any of these services fit your needs:</Typography>
      <List>
        <ul>
          <ListItem><ListItemText><Link to={routes.TEETHCLEANING}>Teeth Cleaning</Link></ListItemText></ListItem>
          <ListItem><ListItemText><Link to={routes.TEETHWHITENING}>Teeth Whitening</Link></ListItemText></ListItem>
          <ListItem><ListItemText><Link to={routes.FLOURIDETREATMENT}>Flouride Treatment</Link></ListItemText></ListItem>
          <ListItem><ListItemText><Link to={routes.PERIODONTALCLEANING}>Periodontal Deep Cleaning</Link></ListItemText></ListItem>
          <ListItem><ListItemText><Link to={routes.DENTALSEALANTS}>Dental Sealants</Link></ListItemText></ListItem>
          <ListItem><ListItemText>Oral Cancer Screening</ListItemText></ListItem>
          <ListItem><ListItemText>Oral Evaluations</ListItemText></ListItem>
          <ListItem><ListItemText>X-rays</ListItemText></ListItem>
        </ul>
      </List>
      <Typography gutterBottom variant='body1'>
        Call Tooth Fairys today at (970) 227-7202 and schedule a checkup and cleaning. Your teeth will be glad you did!
      </Typography>
    </div>
  )
};

Page.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Page);
