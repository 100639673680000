import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import BannerImage from 'global/BannerImage';
import * as routes from 'constants/routes';
import { Link } from 'react-router-dom';
import styles from './styles';
import { withStyles } from '@material-ui/core/styles';

const Page = ({ classes }) => {
  return (
    <div className={classes.root}>
      <BannerImage imageSrc='/images/servicesBanner.jpg' imageAlt='Dental Services' />
      <Typography variant='h5' gutterBottom>Tooth Fairys&apos; Dental Services</Typography>
      <Typography variant='h6' gutterBottom>How we work</Typography>
      <Typography gutterBottom variant='body1'>
        We don&apos;t rush your appointments. You are important to us and we treat you like we would a member of our own family.
      </Typography>
      <Typography variant='h6' gutterBottom>Initial Assessment</Typography>
      <Typography gutterBottom variant='body1'>
        All new patients undergo a complete evaluation including x-rays ( if needed), periodontal charting, and an oral cancer check to determine the state of their oral health and identify any areas of immediate or long-term concern. Medical histories are noted and records are established for future appointments.
      </Typography>

      <Typography gutterBottom variant='body1'>
        Dental Services
      </Typography>
      <List>
        <ul>
          <ListItem><ListItemText><Link to={routes.TEETHCLEANING}>Teeth Cleaning & Deep Dental Cleaning</Link></ListItemText></ListItem>
          <ListItem><ListItemText><Link to={routes.TEETHWHITENING}>Teeth Whitening</Link></ListItemText></ListItem>
          <ListItem><ListItemText><Link to={routes.FLOURIDETREATMENT}>Flouride Treatment</Link></ListItemText></ListItem>
          <ListItem><ListItemText><Link to={routes.PERIODONTALCLEANING}>Periodontal Deep Cleaning</Link></ListItemText></ListItem>
          <ListItem><ListItemText><Link to={routes.DENTALSEALANTS}>Dental Sealants</Link></ListItemText></ListItem>
        </ul>
      </List>
    </div>
  )
};

Page.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Page);
