import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import BannerImage from 'global/BannerImage';
import styles from './styles';
import { withStyles } from '@material-ui/core/styles';

const Page = ({ classes }) => {
  return (
    <div className={classes.root}>
    <BannerImage imageSrc='/images/contactBanner.jpg' imageAlt='Contact Tooth Fairys, PC'/>
      <Typography variant='h5' gutterBottom>Contact Us</Typography>
      <div className={classes.wrap}>
        <div className={classes.left}>
          <Typography variant='body1'>Address:</Typography>
        </div>
        <div className={classes.right}>
          <Typography variant='body1'>2000 Vermont Dr, Suite 210</Typography>
          <Typography variant='body1'>Fort Collins, CO 80525</Typography>
        </div>
      </div>
      <div className={classes.wrap}>
        <div className={classes.left}>
          <Typography variant='body1'>Phone:</Typography>
        </div>
        <div className={classes.right}>
          <Typography variant='body1'>970-227-7202</Typography>
        </div>
      </div>
      <div className={classes.wrap}>
        <div className={classes.left}>
          <Typography variant='body1'>Email:</Typography>
        </div>
        <div className={classes.right}>
          <Typography variant='body1'>julie@toothfairyspc.com</Typography>
        </div>
      </div>
      <div className={classes.wrap}>
        <div className={classes.left}>
          <Typography variant='body1'>Hours:</Typography>
        </div>
        <div className={classes.right}>
          <Typography variant='body1'>Monday to Friday</Typography>
          <Typography variant='body1'>Weekends available</Typography>
          <Typography variant='body1'>Call for an appointment</Typography>
        </div>
      </div>
      <div className={classes.spacer} />
      <Typography variant='h5'>
        Accepting new patients
      </Typography>
    </div>
  )
};

Page.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Page);
