import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import BannerImage from 'global/BannerImage';
import styles from './styles';
import { withStyles } from '@material-ui/core/styles';

const Page = ({ classes }) => {
  return (
    <div className={classes.root}>
      <BannerImage imageSrc='/images/testimonialsBanner.jpg' imageAlt='Tooth Fairys testimonials'/>
      <Typography variant='h5' gutterBottom>Testimonials</Typography>
      <Typography gutterBottom variant='body1'>
        Read what some of our patients are saying about Tooth Fairys and Jullie Elliot:
      </Typography>
      <div className={classes.hr}/>
      <Typography gutterBottom variant='body1'>
        <em>"Tooth Fairys aren't just for kids!! Since we lost our dental insurance in 2009, finding good, affordable dental care for my children and I has been quite challenging. Julie has made it affordable and fun to get our teeth cleaned more than once a year. She cares about our dental health and let's us know if we need to see the dentist. Thanks, Julie, for making it easy and convenient to get our teeth cleaned!!"</em> - Kris H.
      </Typography>
      <div className={classes.hr}/>
      <Typography gutterBottom variant='body1'>
        <em>"Julie is very knowledgeable, trustworthy, professional and friendly while she offers hygiene to all ages and her recommendations of further dental hygiene care is very appreciated and honored. We agree going to Julie is very economical, and her pricing is less than same services offered in a Dental Practice. Steve and I strongly recommend using Julie Jo Elliott, creator of Tooth Fairys, P.C. as your primary / privately owned Hygienist."</em> - Steve & Luanne M.
      </Typography>
      <div className={classes.hr}/>
      <Typography gutterBottom variant='body1'>
        <em>  "I enjoy having Julie clean my teeth, she is always on time, she makes it fun, and of course she does a great job!"</em> - Kevin C
      </Typography>
      <div className={classes.hr}/>
      <Typography gutterBottom variant='body1'>
        <em>"When I visit Julie at Tooth Fairys I know I'll get a great cleaning, my Insurance will be billed and I won't be pressured into a lot treatment I don't need. Thank you Julie for being honest." </em> - Linda Knapp
      </Typography>
    </div>
  )
};

Page.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Page);
